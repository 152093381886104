table{border-collapse:collapse;border-spacing:0;}

.draw_ticai{
    border-top: 1px solid #F3F2F2;
    width: 100%;
    height: 40px;
    line-height: 40px;
}
.draw_ticai span{
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
}
.draw_red_hr{
    background: no-repeat red;
    height: 3px;
    width: 100%;
}

.draw_tc{
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-family: "微软雅黑";
    background-color: #E0E0E0;
}
.draw_tc tr{
    height: 45px;
}

.draw_tc span{
    float: left;
    padding-right: 6px;
    padding-left: 4px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    font-weight: 600;
}

.drawonetr{
   background-color: #E0E0E0; 
}

.drawonetr td{
    padding-left: 6px;
}
.drawtr{
   background-color: #F7F7F7; 
}
.drawtr td{
    padding-left: 6px;
}

.hrtd{
    background: url("../images/hr.png");
}
.draw_red{
    background: url("../images/red.png") no-repeat;
    width: 28px;
    height: 26px;
    text-align: center;
}
.draw_blue{
    background: url("../images/blue.png") no-repeat;
    width: 28px;
    height: 26px;
}